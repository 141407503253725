@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "carouselMixins";

@include media-breakpoint-up(md) {
    .experience-einstein-einsteinCarousel
        .carousel.insufficient-md-slides
        .carousel-control-next,
    .experience-einstein-einsteinCarousel
        .carousel.insufficient-md-slides
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselCategory
        .carousel.insufficient-md-slides
        .carousel-control-next,
    .experience-einstein-einsteinCarouselCategory
        .carousel.insufficient-md-slides
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselProduct
        .carousel.insufficient-md-slides
        .carousel-control-next,
    .experience-einstein-einsteinCarouselProduct
        .carousel.insufficient-md-slides
        .carousel-control-prev,
    .experience-commerce_layouts-carousel
        .carousel.insufficient-md-slides
        .carousel-control-next,
    .experience-commerce_layouts-carousel
        .carousel.insufficient-md-slides
        .carousel-control-prev {
        display: none !important;
    }

    .experience-einstein-einsteinCarousel .carousel.insufficient-md-slides,
    .experience-einstein-einsteinCarouselCategory
        .carousel.insufficient-md-slides,
    .experience-einstein-einsteinCarouselProduct
        .carousel.insufficient-md-slides,
    .experience-commerce_layouts-carousel .carousel.insufficient-md-slides {
        padding-bottom: 0 !important;

        .pd-carousel-indicators {
            display: none !important;
        }
    }

    .carousel.indicators-md {
        @include carousel-indicators();
    }

    .experience-einstein-einsteinCarousel
        .carousel.controls-md
        .carousel-control-next,
    .experience-einstein-einsteinCarouselCategory
        .carousel.controls-md
        .carousel-control-next,
    .experience-einstein-einsteinCarouselProduct
        .carousel.controls-md
        .carousel-control-next,
    .experience-commerce_layouts-carousel
        .carousel.controls-md
        .carousel-control-next {
        display: flex;
    }

    .experience-einstein-einsteinCarousel
        .carousel.controls-md
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselCategory
        .carousel.controls-md
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselProduct
        .carousel.controls-md
        .carousel-control-prev,
    .experience-commerce_layouts-carousel
        .carousel.controls-md
        .carousel-control-prev {
        display: flex;
    }

    // =============================================================================================
    .experience-einstein-einsteinCarousel .carousel-md-2 .carousel-control-prev,
    .experience-einstein-einsteinCarousel .carousel-md-2 .carousel-control-next,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-2
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-2
        .carousel-control-next,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-2
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-2
        .carousel-control-next,
    .experience-commerce_layouts-carousel .carousel-md-2 .carousel-control-prev,
    .experience-commerce_layouts-carousel
        .carousel-md-2
        .carousel-control-next {
        top: 43%;
    }

    .carousel-md-2.indicators-md {
        .carousel-control-prev,
        .carousel-control-next {
            top: 36%;
        }
    }

    .carousel-md-2
        .carousel-item.active:not(.carousel-item-right):not(
            .carousel-item-left
        ),
    .carousel-md-2
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item {
        transition: none;
    }

    .carousel-md-2 .carousel-item-next,
    .carousel-md-2 .carousel-item-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
    }

    .carousel-md-2 .active.carousel-item + .carousel-item + .carousel-item {
        @include carousel-right-most-element(-50%);
    }

    .carousel-md-2 .carousel-item-prev.carousel-item-right {
        @include carousel-left-most-element(-50%);
        @include carousel-transform(100%);
    }

    .carousel-md-2
        .active.carousel-item-left
        + .carousel-item-next.carousel-item-left,
    .carousel-md-2 .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-md-2
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;

        @include carousel-transform(-100%);
    }

    .carousel-md-2
        .active.carousel-item-right
        + .carousel-item-prev.carousel-item-right,
    .carousel-md-2 .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-md-2
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;
        display: block;

        @include carousel-transform(100%);
    }

    .carousel-md-2 .active,
    .carousel-md-2 .active + .carousel-item {
        display: block;
    }

    .experience-einstein-einsteinCarousel
        .carousel.carousel-md-2.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselCategory
        .carousel.carousel-md-2.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselProduct
        .carousel.carousel-md-2.insufficient-md-slides
        .carousel-item,
    .experience-commerce_layouts-carousel
        .carousel.carousel-md-2.insufficient-md-slides
        .carousel-item {
        display: block !important;
    }

    // =============================================================================================
    .experience-einstein-einsteinCarousel .carousel-md-3 .carousel-control-prev,
    .experience-einstein-einsteinCarousel .carousel-md-3 .carousel-control-next,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-3
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-3
        .carousel-control-next,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-3
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-3
        .carousel-control-next,
    .experience-commerce_layouts-carousel .carousel-md-3 .carousel-control-prev,
    .experience-commerce_layouts-carousel
        .carousel-md-3
        .carousel-control-next {
        top: 40%;
    }

    .carousel-md-3.indicators-md {
        .carousel-control-prev,
        .carousel-control-next {
            top: 33%;
        }
    }

    .carousel-md-3
        .carousel-item.active:not(.carousel-item-right):not(
            .carousel-item-left
        ),
    .carousel-md-3
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item,
    .carousel-md-3
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item {
        transition: none;
    }

    .carousel-md-3 .carousel-item-next,
    .carousel-md-3 .carousel-item-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
    }

    .carousel-md-3
        .active.carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        @include carousel-right-most-element(-33.3333%);
    }

    .carousel-md-3 .carousel-item-prev.carousel-item-right {
        @include carousel-left-most-element(-33.3333%);
        @include carousel-transform(100%);
    }

    .carousel-md-3
        .active.carousel-item-left
        + .carousel-item-next.carousel-item-left,
    .carousel-md-3 .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-md-3
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item,
    .carousel-md-3
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;

        @include carousel-transform(-100%);
    }

    .carousel-md-3
        .active.carousel-item-right
        + .carousel-item-prev.carousel-item-right,
    .carousel-md-3 .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-md-3
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item,
    .carousel-md-3
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;
        display: block;

        @include carousel-transform(100%);
    }

    .carousel-md-3 .active,
    .carousel-md-3 .active + .carousel-item,
    .carousel-md-3 .active + .carousel-item + .carousel-item {
        display: block;
    }

    .experience-einstein-einsteinCarousel
        .carousel.carousel-md-3.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselCategory
        .carousel.carousel-md-3.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselProduct
        .carousel.carousel-md-3.insufficient-md-slides
        .carousel-item,
    .experience-commerce_layouts-carousel
        .carousel.carousel-md-3.insufficient-md-slides
        .carousel-item {
        display: block !important;
    }

    // =============================================================================================
    .experience-einstein-einsteinCarousel .carousel-md-4 .carousel-control-prev,
    .experience-einstein-einsteinCarousel .carousel-md-4 .carousel-control-next,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-4
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-4
        .carousel-control-next,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-4
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-4
        .carousel-control-next,
    .experience-commerce_layouts-carousel .carousel-md-4 .carousel-control-prev,
    .experience-commerce_layouts-carousel
        .carousel-md-4
        .carousel-control-next {
        top: 37%;
    }

    .carousel-md-4.indicators-md {
        .carousel-control-prev,
        .carousel-control-next {
            top: 30%;
        }
    }

    .carousel-md-4
        .carousel-item.active:not(.carousel-item-right):not(
            .carousel-item-left
        ),
    .carousel-md-4
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item,
    .carousel-md-4
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item,
    .carousel-md-4
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        transition: none;
    }

    .carousel-md-4 .carousel-item-next,
    .carousel-md-4 .carousel-item-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
    }

    .carousel-md-4
        .active.carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        @include carousel-right-most-element(-25%);
    }

    .carousel-md-4 .carousel-item-prev.carousel-item-right {
        @include carousel-left-most-element(-25%);
        @include carousel-transform(100%);
    }

    .carousel-md-4
        .active.carousel-item-left
        + .carousel-item-next.carousel-item-left,
    .carousel-md-4 .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-md-4
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item,
    .carousel-md-4
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-4
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;

        @include carousel-transform(-100%);
    }

    .carousel-md-4
        .active.carousel-item-right
        + .carousel-item-prev.carousel-item-right,
    .carousel-md-4 .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-md-4
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item,
    .carousel-md-4
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-4
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;
        display: block;

        @include carousel-transform(100%);
    }

    .carousel-md-4 .active,
    .carousel-md-4 .active + .carousel-item,
    .carousel-md-4 .active + .carousel-item + .carousel-item,
    .carousel-md-4 .active + .carousel-item + .carousel-item + .carousel-item {
        display: block;
    }

    .experience-einstein-einsteinCarousel
        .carousel.carousel-md-4.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselCategory
        .carousel.carousel-md-4.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselProduct
        .carousel.carousel-md-4.insufficient-md-slides
        .carousel-item,
    .experience-commerce_layouts-carousel
        .carousel.carousel-md-4.insufficient-md-slides
        .carousel-item {
        display: block !important;
    }

    // =============================================================================================
    .experience-einstein-einsteinCarousel .carousel-md-6 .carousel-control-prev,
    .experience-einstein-einsteinCarousel .carousel-md-6 .carousel-control-next,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-6
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselCategory
        .carousel-md-6
        .carousel-control-next,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-6
        .carousel-control-prev,
    .experience-einstein-einsteinCarouselProduct
        .carousel-md-6
        .carousel-control-next,
    .experience-commerce_layouts-carousel .carousel-md-6 .carousel-control-prev,
    .experience-commerce_layouts-carousel
        .carousel-md-6
        .carousel-control-next {
        top: 29.5%;
    }

    .carousel-md-6.indicators-md {
        .carousel-control-prev,
        .carousel-control-next {
            top: 22.5%;
        }
    }

    .carousel-md-6
        .carousel-item.active:not(.carousel-item-right):not(
            .carousel-item-left
        ),
    .carousel-md-6
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item,
    .carousel-md-6
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        transition: none;
    }

    .carousel-md-6 .carousel-item-next,
    .carousel-md-6 .carousel-item-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
    }

    .carousel-md-6
        .active.carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        @include carousel-right-most-element(-16.66667%);
    }

    .carousel-md-6 .carousel-item-prev.carousel-item-right {
        @include carousel-left-most-element(-16.66667%);
        @include carousel-transform(100%);
    }

    .carousel-md-6
        .active.carousel-item-left
        + .carousel-item-next.carousel-item-left,
    .carousel-md-6 .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-md-6
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-next.carousel-item-left
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;

        @include carousel-transform(-100%);
    }

    .carousel-md-6
        .active.carousel-item-right
        + .carousel-item-prev.carousel-item-right,
    .carousel-md-6 .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-md-6
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .carousel-item-prev.carousel-item-right
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        position: relative;
        visibility: visible;
        display: block;

        @include carousel-transform(100%);
    }

    .carousel-md-6 .active,
    .carousel-md-6 .active + .carousel-item,
    .carousel-md-6 .active + .carousel-item + .carousel-item,
    .carousel-md-6 .active + .carousel-item + .carousel-item + .carousel-item,
    .carousel-md-6
        .active
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item,
    .carousel-md-6
        .active
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item
        + .carousel-item {
        display: block;
    }

    .experience-einstein-einsteinCarousel
        .carousel.carousel-md-6.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselCategory
        .carousel.carousel-md-6.insufficient-md-slides
        .carousel-item,
    .experience-einstein-einsteinCarouselProduct
        .carousel.carousel-md-6.insufficient-md-slides
        .carousel-item,
    .experience-commerce_layouts-carousel
        .carousel.carousel-md-6.insufficient-md-slides
        .carousel-item {
        display: block !important;
    }
}
